import intlTelInput from 'intl-tel-input';

let input = document.querySelector("#person_phone_helper");
let inputOriginal = document.querySelector("#person_phone");
let errorMsg = document.querySelector("#person_phone-error-msg");

let input2 = document.querySelector("#phone_helper");
let inputOriginal2 = document.querySelector("#phone");
let errorMsg2 = document.querySelector("#phone-error-msg");
// "IS_POSSIBLE": 0,
// "INVALID_COUNTRY_CODE": 1,
// "TOO_SHORT": 2,
// "TOO_LONG": 3,
// "IS_POSSIBLE_LOCAL_ONLY": 4,
// "INVALID_LENGTH": 5,
let errorMap = ["Číslo je neplatné", "Neplatný kód krajiny", "Číslo je príliš krátke", "Číslo je príliš dlhé", "Číslo je neplatné", "Číslo je neplatné"];

window.resetPhoneNumber = function resetPhoneNumber(input, inputOriginal, errorMsg) {
    input.classList.remove("is-invalid");
    input.classList.remove("is-valid");
    inputOriginal.value = null;
    errorMsg.innerHTML = "";
    errorMsg.classList.add("d-none");
};

function isValidRegex(text) {
    if (/(^([0-9\s\-+()]*)$)/m.test(text)) {
        return true;
    }
    return false;
}

window.handleChangeInput = function handleChangeInput(iti, input, inputOriginal, errorMsg) {
    resetPhoneNumber(input, inputOriginal, errorMsg);
    if (input.value.trim()) {
        if (iti.isValidNumber() && isValidRegex(input.value.trim())) {
            input.classList.add("is-valid");
            inputOriginal.value = iti.getNumber();
        }
        else {
            let errorCode = 0;
            if (iti.isValidNumber()) {
                let errorCode = iti.getValidationError();
                if (!errorMap.hasOwnProperty(errorCode)) {
                    errorCode = 0;
                }
            }

            errorMsg.innerHTML = errorMap[errorCode];
            input.classList.add("is-invalid");
            errorMsg.classList.remove("d-none");
        }
    }
};

if(input) {
    let iti = intlTelInput(input, {
        initialCountry: 'sk',
        utilsScript: "../../js/flags/utils.js",
        preferredCountries: ['sk', 'cz'],
    });

    input.addEventListener('change', function () {
        handleChangeInput(iti, input, inputOriginal, errorMsg);
    });
    input.addEventListener('keyup', function () {
        handleChangeInput(iti, input, inputOriginal, errorMsg);
    });
}

if(input2) {
    let iti2 = intlTelInput(input2, {
        initialCountry: 'sk',
        utilsScript: "../../js/flags/utils.js",
        preferredCountries: ['sk', 'cz'],
    });

    input2.addEventListener('change', function () {
        handleChangeInput(iti2, input2, inputOriginal2, errorMsg2);
    });
    input2.addEventListener('keyup', function () {
        handleChangeInput(iti2, input2, inputOriginal2, errorMsg2);
    });
}

