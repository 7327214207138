// // import { createPopper } from '@popperjs/core';
// // let tooltipTriggerList = [].slice.call(
// //     document.querySelectorAll('[data-bs-toggle="tooltip"]')
// // );
// // let tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
// //     return new Tooltip(tooltipTriggerEl);
// // });
//
//
// import { createPopper } from '@popperjs/core';
// // const popcorn = document.querySelector('#popcorn');
// // const tooltip = document.querySelector('#tooltip');
// // createPopper(popcorn, tooltip, {
// //     placement: 'right',
// // });
// //
//
// let popperListItems = [].slice.call(
//     document.querySelectorAll('[data-tooltip-id="tooltip"]')
// );
// console.log(popperListItems);
// console.log('--');
// let popperListItems = document.querySelectorAll('[role="tooltip"]');
// document.querySelectorAll('[role="tooltip"]').map(function (el) {
//     console.log(el);
// });
// popperListItems.map(function (tooltipTriggerEl) {
//     console.log(tooltipTriggerEl);
//     console.log();
//     let elTooltip = document.getElementById(tooltipTriggerEl.getAttribute('data-tooltip-target'));
//     if (elTooltip) {
//         createPopper(tooltipTriggerEl, elTooltip, {
//             placement: 'right',
//         });
//     }
//
//     // return new Tooltip(tooltipTriggerEl);
//
//
// });
