import { add } from "lodash";
import "./header.js";
// import "./button";
import "./popper";
//import "./scroll.js";
//import "./scroll-up.js";
import "./toastify";
import "./intl-tel-input";
// import fslightbox from "fslightbox";
//import "./contact-form";
// import NiceSelect from "nice-select2/dist/js/nice-select2";
// import "nice-select2/src/js/nice-select2";
import '@fortawesome/fontawesome-free/js/all.min';

window.sendOnlyOnce = function sendOnlyOnce(el, idForm) {
    if (document.getElementById(idForm)) {
        el.disabled = true;
        let loaders = el.getElementsByClassName('loader-icon');
        if(loaders.length > 0) {
            loaders[0].style.visibility = 'visible';
        }
        let texts = el.getElementsByClassName('loader-text');
        if(texts.length > 0) {
            texts[0].style.visibility = 'hidden';
        }
        document.getElementById(idForm).submit();
    }
    // setTimeout(function() {
    //     el.disabled = false
    // }, 5000);
};


// const niceSelectElements = document.getElementsByClassName("nice-select");
// for (let i = 0; i < niceSelectElements.length; i++) {
//     console.log(niceSelectElements[i]);
//     new NiceSelect(niceSelectElements[i], {searchable: true});
// }

